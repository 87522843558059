import React, {useState} from 'react';



function VLine () {
    return (
    <div className="vertical-Line"></div>
    );
}

function ActionBtn (props) {
    return (
    <button className="cls-btn" onClick={props.onClick}>{props.value}</button>
    );
}

function CopyBtn (props) {
    const [used ,setUsed] = useState("copy")
    
    const handleCopy = (content) =>{
        setUsed("copied!");
        var copyText = document.getElementById(content);
        copyText.select();
        copyText.setSelectionRange(0, 99999);

        document.execCommand("copy");

    }
    return(
        <>  
            <div className="copy-txt">
                <p>{props.lab}: {props.cont}</p>
                <button className="cpy-btn" onClick={() =>handleCopy(props.cont)}>{used}</button>
            
            </div>
            <textarea className="hide" id={props.cont} defaultValue={props.cont}/>
        </>
    );
    
}

function Headline (props) {
    return (
    <>
    <div className="headline">
    <h1>{props.value}</h1>
    </div>
    </>
    );
}
export {CopyBtn, ActionBtn, VLine, Headline};