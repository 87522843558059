import React, { useState } from 'react';

import {VLine, ActionBtn, CopyBtn, Headline} from "./design-assets";
import "./home.css"

/*Parameters*/
const open = 1;
const amount = 1000.00;

const invs = {
    inv1: {
        id: "001",
        invname: "INV_HMSC_2000",
        amnt: 400,
        due: 5
    }, 

    inv2: {
        id: "002",
        invname: "INV_HMSC_2000",
        amnt: 400,
        due: 6
    }, 

    inv3: {
        id: "003",
        invname: "INV_HMSC_2000",
        amnt: 400,
        due: 7
    }, 

    inv4: {
        id: "004",
        invname: "INV_HMSC_2000",
        amnt: 400,
        due: 8
    }

};



function TableElement (props) {
    return ( 
        <tr key={props.id} className={props.alert}>
            <td>{props.name} </td>
            <td>{props.amount} €</td>
            <td>{props.due}  Days</td>
        </tr> 
    )
}

function InvoiceTable (props) {
    
    
    const invsList = () => {

        let row = [];
        
        Object.values(invs).forEach(value => {
            let alertColor = "";
            if (value["due"] < 6) {
                alertColor = "due";
            }
            
            row.push(<TableElement alert={alertColor} key={value["id"]} name={value["invname"]} amount={value["amnt"].toFixed(2)} due={value["due"]}/>)
            
        });

        return row;
    }


    return (
        
        <>
        
        <table>
        <tbody>
           <tr>
                <th>Invoice</th>
                <th>Amount</th>
                <th>Due-Day</th>

           </tr>
            {invsList()}
           </tbody>
        </table>
        </>
    );
}

function OpenInvoice () {

    const [showInvoice , setshowInvoice] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const handleToggle = (opt) => {
        /* Wechsel zwischen den Informationen die abgebildet werden */
        if (opt === "Invoice") {
            setshowInvoice(!showInvoice);
            setShowInfo(false);
        } else if (opt === "Info") {
            setshowInvoice(false);
            setShowInfo(!showInfo);
        }
    };

    const handleHide = (opt) => {
        if (opt === "Invoice") {
            setshowInvoice(false);
            
        } else if (opt === "Info") {
            
            setShowInfo(false);
        }
    }

    return (
        <>
        
        
        <div className="pay-Status">
            <p>open amount:</p>
            <VLine/>
            <button onClick={() => handleToggle("Invoice")} className="pay-btn">{amount.toFixed(2)}€</button>
            <VLine/>
            <button  onClick={() =>handleToggle("Info")} className="pay-btn">Pay now</button>
        </div>
        
        {showInvoice ? 
            <div className="pay-Info">
                <InvoiceTable/>

                <ActionBtn value="" onClick={() => handleHide("Invoice")}/>
            </div>

            : null}

        {showInfo ?
            <div className="pay-Info">
                    <CopyBtn lab="Name"cont="Youri Sinziri"/>
                    <CopyBtn lab="IBAN" cont="IBAN"/>
                    <CopyBtn lab="BIC" cont="BIC"/>
                    <CopyBtn lab="Purpose" cont="HMSC.Invc.all"/>
                    <ActionBtn value="" onClick={() => handleHide("Info")}/>
            </div>

        : null}
        
        </>
    )
}

function NoOpenInvoice () {
    return(
    <>
    <div className="pay-Status">
    <p>Good News - nothing to do here :)</p>
    </div>
    </>
    );
}

function InvoiceAsset (props) {

    return (
        <>
        <div className="InfoCard">
            <p className="AssetText">{props.name}</p>

            <p className="AssetText">{props.date}</p>
            <p className="AssetText">{props.amount} €</p>
            <ActionBtn />
        </div>
        </>
    );
}

function Index () {
    return (
    <>
    <div className="index">
        <VLine/>
        <p>MAI 2021</p>   
    </div>
    
    </>
    )
}
function Home () {
    return (
        <>
            <div className="header"> 
                { open ? <OpenInvoice/> : <NoOpenInvoice/> }
            </div>
           
            <div className="main">
                <div className="container">
                    <Headline value="Invoices"/>

                    <div className="Subcont">
                        <Index/>
                        <InvoiceAsset name="Inv-bla-0000" date="02.05.2021" amount="300.00"/>
                        <InvoiceAsset name="Inv-bla-0000" date="02.05.2021" amount="300.00"/>
                        <InvoiceAsset name="Inv-bla-0000" date="02.05.2021" amount="300.00"/>
                        <InvoiceAsset name="Inv-bla-0000" date="02.05.2021" amount="300.00"/>
                        <InvoiceAsset name="Inv-bla-0000" date="02.05.2021" amount="300.00"/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;