import React from "react";
import ReactDOM from "react-dom";
/* Modules */
import LoginForm from "./form.js";
import Nav from "./nav.js";
import Home from "./home";

/* Styles */
import "./stylesheet.css";
import "./colors.css";
import "./fonts.css";


function Login () {
    return(
      <LoginForm/>
    )
};

function Homescreen () {

  return (
    <>
      <Home/>
      <Nav/>
    </>

  )
}
function App() {

  const logedin = false;

  if (logedin) {
  return (
  <>

    <Login/>
  
  
  </>
  );
  } else {
    return (
    <>
    <Homescreen />
    </>
    );
  };

}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
