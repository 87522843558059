import React, { useState } from 'react';
import './nav.css';


export default function Nav (props) {

    const [aktiv, setAktiv] = useState("home");

    
    let home = "home_filled.svg";
    let pass = "passcode_outline.svg";
    let settings = "settings_outline.svg";

    switch (aktiv) {
        case "home":
            home = "home_filled.svg";
            pass = "passcode_outline.svg";
            settings = "settings_outline.svg";
            break;
        case "pass":
            home = "home_outline.svg";
            pass = "passcode_filled.svg";
            settings = "settings_outline.svg";
            break;
        case "settings":
            home = "home_outline.svg";
            pass = "passcode_outline.svg";
            settings = "settings_filled.svg";
            break;
        case "logout":
            home = "home_outline.svg";
            pass = "passcode_outline.svg";
            settings = "settings_outline.svg"; 
            break;
        default: 
            home = "home_filled.svg";
            pass = "passcode_outline.svg";
            settings = "settings_outline.svg";

    };

   

    return(
        
        <div className="NavContainer">
            
            <ul className="navBar">
                <li className="link" onClick={() => setAktiv("home")}>  
                    <img src={home} alt=""/>
                   
                </li>
                
                <li className="link" onClick={() => setAktiv("pass")}>
                    <img src={pass} alt=""/>
                    

                </li>
                
                <li className="link" onClick={() => setAktiv("settings")}>
                    <img src={settings} alt=""/>
                   
                </li>
                
                <li className="link" onClick={() => setAktiv("logout")}>
                    <img src="log_out.svg" alt="" />
                    
                </li>
            </ul>
        </div>
    )

}

